import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import SplitType from "split-type";

const Text = ({ children }) => {
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    const myText = new SplitType(textRef.current);
    const chars = myText.chars;

    gsap.set(chars, {
      y: 100,
      opacity: 0,
      rotateX: -90
    });

    gsap.to(chars, {
      y: 0,
      opacity: 1,
      rotateX: 0,
      stagger: 0.02,
      duration: 0.8,
      ease: "power4.out"
    });

    const distortAnimation = () => {
      chars.forEach((char, i) => {
        const time = Date.now() * 0.001;
        const wave = Math.sin(time * 2 + i * 0.5) * 5;

        gsap.to(char, {
          y: wave,
          rotateX: wave,
          rotateY: wave,
          duration: 0.5
        });
      });

      animationRef.current = requestAnimationFrame(distortAnimation);
    };

    distortAnimation();

    const handleMouseMove = (e) => {
      if (window.innerWidth <= 768) return;

      const { clientX, clientY } = e;
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const deltaX = (clientX - centerX) / centerX;
      const deltaY = (clientY - centerY) / centerY;

      gsap.to(chars, {
        x: deltaX * 20,
        y: deltaY * 20 + chars.index * Math.sin(Date.now() * 0.001) * 5,
        rotateX: deltaY * 10,
        rotateY: deltaX * 10,
        stagger: 0.02,
        duration: 0.6,
        ease: "power2.out"
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      cancelAnimationFrame(animationRef.current);
    };
  }, [children]);

  return (
    <Container ref={containerRef}>
      <TextWrapper
        ref={textRef}
        style={{
          transform: "perspective(1000px)",
          transformStyle: "preserve-3d"
        }}
      >
        {children}
      </TextWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  perspective: 1000px;
  margin-left: 10px;
`;

const TextWrapper = styled.p`
  @media (max-width: 700px) {
    font-size: 75px;
  }
  @media (max-width: 400px) {
    font-size: 55px;
  }
  margin-left: 2px;
  font-size: 150px;
  width: fit-content;
  color: white;
  mix-blend-mode: difference;

  .char {
    display: inline-block;
    transform-style: preserve-3d;
    transition: text-shadow 0.3s ease;
    will-change: transform;

    &:hover {
    }
  }
`;

export default Text;
