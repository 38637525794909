import { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import CircleFollowMouse from "./components/Mouse";
import Text from "./components/Text";
import Timeline from "./components/Timeline";
import { setLocale, getLocale } from "./helpers/i18n";
import Loader from "./components/Loader";
import SkillsSection from "./components/SkillsSections";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ContactForm from "./components/Contact";
import Music from "./components/Music";
import LangSelect from "./components/LangSelect";
import { VideoContext } from "./components/VideoContext";

export const locale = getLocale();

gsap.registerPlugin(ScrollTrigger);

const videos = [
  "/exit.mp4",
  "/symble.webm",
  "/transpare.mov",
  "/webacademie.mp4",
  "/smsmode.mp4",
  "/epitech.mp4"
];

function App() {
  const lineRef = useRef(null);

  const wrapperRef = useRef(null);

  const { setPreloadedVideos } = useContext(VideoContext);
  const [isLoading, setIsLoading] = useState(true);
  const [videosLoaded, setVideosLoaded] = useState(0);

  useEffect(() => {
    const preloadVideos = async () => {
      const videoMap = {};
      const promises = videos.map(
        (src) =>
          new Promise((resolve) => {
            const video = document.createElement("video");
            video.src = src;
            video.preload = "auto";
            video.onloadeddata = () => {
              videoMap[src] = video.src;
              setVideosLoaded((prev) => prev + 1);
              resolve();
            };
            video.onerror = () => resolve();
          })
      );

      await Promise.all(promises);
      setPreloadedVideos(videoMap);
      setIsLoading(false);
    };

    preloadVideos();
  }, [setPreloadedVideos]);

  const handleChange = (input) => {
    setLocale(input);
  };
  useEffect(() => {
    if (!isLoading && lineRef.current) {
      gsap.set(lineRef.current, {
        scaleY: 0,
        transformOrigin: "top"
      });

      const trigger = ScrollTrigger.create({
        trigger: lineRef.current,
        start: "top 80%",
        end: "bottom top",
        scrub: 1,
        animation: gsap.to(lineRef.current, {
          scaleY: 1,
          duration: 2,
          paused: true
        })
      });

      return () => trigger.kill();
    }
  }, [isLoading]);

  return (
    <ColorWrapper ref={wrapperRef}>
      {isLoading ? (
        <Loader
          progress={Math.floor((videosLoaded / videos.length) * 100)}
          setIsLoading={setIsLoading}
        />
      ) : (
        <Wrapper>
          <div className="section">
            <LanguageDropDown>
              <LangSelect currentLang={locale} changeLanguage={handleChange} />
            </LanguageDropDown>
            <Text>yann</Text>
            <Text>Rostingt</Text>
            <Line ref={lineRef}></Line>
          </div>
          <SkillsSection />
          <div className="section">
            <Timeline></Timeline>
          </div>
          <div className="section">
            <Footer>
              <div>
                <ContactForm></ContactForm>
              </div>
            </Footer>
          </div>
          <CircleFollowMouse />
        </Wrapper>
      )}
    </ColorWrapper>
  );
}

const ColorWrapper = styled.div`
  padding: 10px;
  background-color: #000;
`;

const Line = styled.div`
  height: 100%;
  border-radius: 1rem;
  margin-left: 7px;
  width: 2px;
  position: absolute;
  background: linear-gradient(to bottom, #000, #fff);
  transform-origin: top center;
  will-change: transform;
`;

const Wrapper = styled.div`
  height: auto;
  background-color: #000;
  & > div:first-child {
    height: 100vh;
    position: relative;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 3rem;
  padding-top: 3rem;
  border: 0 solid rgba(255, 255, 255, 0.2);
  border-top-width: 1px;
  height: 600px;
  & > div:first-child {
    display: flex;
  }
`;

const LanguageDropDown = styled.div`
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
`;

export default App;
