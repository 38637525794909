import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { translate as t } from "../helpers/i18n";

import Angular from "../assets/logo/angular.png";
import Js from "../assets/logo/js.png";
import Node from "../assets/logo/node.png";
import ReactIcon from "../assets/logo/react.png";
import Styled from "../assets/logo/styled.png";
import Ts from "../assets/logo/ts.png";
import Storybook from "../assets/logo/storybook.png";
import Tanstack from "../assets/logo/tanstack.png";
import Figma from "../assets/logo/figma.png";
import Git from "../assets/logo/git.png";
import Cypress from "../assets/logo/cypress.png";
import CoreUi from "../assets/logo/coreui.jpg";
import Gsap from "../assets/logo/gsap.jpg";
import Swagger from "../assets/logo/swagger.png";
import Netlify from "../assets/logo/netlify.png";
import Vercel from "../assets/logo/vercel.png";
import Aws from "../assets/logo/aws.png";
import Docker from "../assets/logo/docker.png";
import Wordpress from "../assets/logo/wordpress.png";
import Webflow from "../assets/logo/webflow.png";
import Jira from "../assets/logo/jira.png";
import Mongo from "../assets/logo/mongo.png";

const SkillsSection = () => {
  const sectionRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
    document.documentElement.style.setProperty("--scrollbar-color", "white");

    return () => {
      document.body.style.backgroundColor = "#000000";
      document.documentElement.style.setProperty("--scrollbar-color", "white");
    };
  }, []);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const mm = gsap.matchMedia();

      mm.add("(min-width: 769px)", () => {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            pin: true,
            pinSpacing: true,
            start: "center center",
            end: () =>
              `+=${containerRef.current.offsetWidth - window.innerWidth}`,
            scrub: 2
          }
        });

        tl.to(containerRef.current, {
          x: () => -(containerRef.current.offsetWidth - window.innerWidth),
          ease: "none"
        });
      });
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  const skills = [
    {
      name: "TypeScript",
      icon: Ts,
      color: "#007ACC",
      article: {
        title: "Les 5 avantages de TypeScript face à JavaScript",
        preview:
          "Les avantages de TypeScript pour les développeurs web sont nombreux, et viennent s'ajouter aux avantages de JavaScript.",
        link: "https://www.lemon-interactive.fr/actualites/developpement/5-avantages-typescript-face-javascript/"
      }
    },
    {
      name: "JavaScript",
      icon: Js,
      color: "#F7DF1E",
      article: {
        title:
          "Dernières fonctionnalités, performances, techniques modernes et codage avancé",
        preview:
          "JavaScript est un langage essentiel dans le développement web moderne",
        link: "https://dev.to/abdulnasirolcan/javascript-2024-latest-features-performance-modern-techniques-and-advanced-coding-34gm"
      }
    },
    {
      name: "React",
      icon: ReactIcon,
      color: "#61DAFB",
      article: {
        title: "React Blog",
        preview:
          "Ce blog est la source officielle des mises à jour de l'équipe React.",
        link: "https://react.dev/blog"
      }
    },
    {
      name: "Angular",
      icon: Angular,
      color: "#DD0031",
      article: {
        title: "Server-Side Rendering (SSR) avec Angular",
        preview: "Quelques bonnes pratiques avec Angular",
        link: "https://www.angulararchitects.io/blog/complete-guide-for-server-side-rendering-ssr-in-angular/"
      }
    },
    {
      name: "Node",
      icon: Node,
      color: "#339933",
      article: {
        title: "Nouveautés de Node.js 2024",
        preview:
          "Node.js s'apprête à révolutionner encore davantage le développement des applications dorsales grâce à ses dernières mises à jour.",
        link: "https://daily.dev/fr-fr/blog/whats-new-in-nodejs-2024"
      }
    },
    {
      name: "Styled Components",
      icon: Styled,
      color: "#DB7093",
      article: {
        title: "Scopez votre CSS avec styled components",
        preview:
          "Avec styled components, la principale chose à comprendre est que tout est composant.",
        link: "https://openclassrooms.com/fr/courses/7150606-creez-une-application-react-complete/7255266-scopez-votre-css-avec-styled-components"
      }
    },
    {
      name: "TanStack Query",
      icon: Tanstack,
      color: "#FF4154",
      article: {
        title: "Pourquoi utiliser TanStack Query ?",
        preview:
          "TanStack Query est une bibliothèque utilisée pour la récupération et la mise en cache de données dans les applications web modernes.",
        link: "https://medium.com/@eda.belge/why-should-use-tanstack-query-c1108a1dc798"
      }
    },
    {
      name: "TanStack Table",
      icon: Tanstack,
      color: "#FF4154",
      article: {
        title: "La table TanStack expliquée : Tout ce qu'il faut savoir",
        preview:
          "Bien qu'il existe de nombreuses bibliothèques pour gérer les tableaux dans React, peu offrent la flexibilité, la performance et la facilité d'utilisation de TanStack Table.",
        link: "https://www.creowis.com/blog/tanstack-table-explained-everything-you-need-to-know"
      }
    },
    {
      name: "Storybook",
      icon: Storybook,
      color: "#FF4785",
      article: {
        title: "Introduction à Storybook",
        preview: "Pourquoi utiliser Storybook ?",
        link: "https://medium.com/@turkishtechnology/introduction-to-storybook-67a043b234c8"
      }
    },
    {
      name: " ",
      icon: Cypress,
      color: "#fff",
      article: {
        title: "Cypress + Storybook",
        preview:
          "Une fois que nous savons à quoi doivent ressembler nos composants, nous voulons les tester",
        link: "https://www.cypress.io/blog/cypress-x-storybook-2-0"
      }
    },
    {
      name: "Git",
      icon: Git,
      color: "#F05032",
      article: {
        title: "Apprendre git",
        preview: "La manière la plus visuelle et interactive d'apprendre Git",
        link: "https://learngitbranching.js.org/"
      }
    },
    {
      name: "Figma",
      icon: Figma,
      color: "#F24E1E",
      article: {
        title: "Découvrez de nouvelles façons d'améliorer vos dessins",
        preview: "Figma Config 2024",
        link: "https://www.figma.com/whats-new/"
      }
    },
    {
      name: "MongoDB",
      icon: Mongo,
      color: "#13AA52",
      article: {
        title: "Le coût de la méconnaissance de MongoDB",
        preview: "Le niveau de performance que vous pouvez obtenir,",
        link: "https://www.mongodb.com/developer/products/mongodb/cost-of-not-knowing-mongodb/"
      }
    },
    {
      name: "Docker",
      icon: Docker,
      color: "#2496ED",
      article: {
        title: "Conseils et astuces Docker pour 2024",
        preview: "Optimiser la productivité avec Docker",
        link: "https://www.docker.com/blog/8-top-docker-tips-tricks-for-2024/"
      }
    },
    {
      name: "AWS",
      icon: Aws,
      color: "#FF9900",
      article: {
        title: "AWS Trends",
        preview:
          "Tendances et innovations qui façonnent l'informatique dématérialisée",
        link: "https://medium.com/codex/stay-ahead-of-the-curve-aws-trends-that-are-turning-heads-in-2024-dabc3c0d4588"
      }
    },
    {
      name: "Jira",
      icon: Jira,
      color: "#2684FF",
      article: {
        title: "Gestion de projet AGILE",
        preview:
          "Jira est plus facile et plus flexible pour le style de travail de chaque équipe",
        link: "https://www.atlassian.com/blog/announcements/the-new-jira"
      }
    },
    {
      name: "Wordpress",
      icon: Wordpress,
      color: "#53A7C7",
      article: {
        title: "Les dernières actualités WordPress",
        preview: "",
        link: "https://news.wpmarmite.com/actualites/"
      }
    },
    {
      name: "Webflow",
      icon: Webflow,
      color: "#1976D2",
      article: {
        title: "Les 5 avantages principaux de Webflow",
        preview: "Webflow est un outil de développement web innovant",
        link: "https://www.framely.studio/articles/les-5-avantages-principaux-de-webflow"
      }
    },
    {
      name: "Swagger",
      icon: Swagger,
      color: "#6D9A00",
      article: {
        title: "Swagger : Documentation API Efficace",
        preview: "Comment documenter vos APIs REST avec Swagger/OpenAPI...",
        link: "https://swagger-article.com"
      }
    },
    {
      name: "Netlify",
      icon: Netlify,
      color: "#25C7B7",
      article: {
        title: "Netlify Compose",
        preview:
          "Netlify Compose 2024 a été l'occasion de construire ensemble un meilleur web.",
        link: "https://www.netlify.com/blog/netlify-compose-2024-recap/"
      }
    },
    {
      name: " ",
      icon: Vercel,
      color: "#fff",
      article: {
        title: "Vercel Ship recap",
        preview: "A propos de la puissance du nuage frontal",
        link: "https://vercel.com/blog/vercel-ship-2024"
      }
    },
    {
      name: "GSAP",
      icon: Gsap,
      color: "#0BD944",
      article: {
        title: "GSAP showcases",
        preview: "Créez des animations avec GSAP",
        link: "https://gsap.com/showcase/?page=1"
      }
    },
    {
      name: "CoreUI",
      icon: CoreUi,
      color: "#3421DB",
      article: {
        title:
          "CoreUI vous aide à créer des applications web fiables plus rapidement qu'auparavant",
        preview:
          "Accélérez le développement de vos applications et sites réactifs",
        link: "https://coreui.io/"
      }
    }
  ].sort(() => Math.random() - 0.5);

  return (
    <Section ref={sectionRef} className="skills-section">
      <Container ref={containerRef}>
        <MasonryGrid>
          {skills.map((skill, index) => (
            <Card
              key={`${skill.name}-${index}`}
              $color={skill.color}
              $size={index % 6}
            >
              <CardContent>
                <TopSection>
                  <IconWrapper $color={skill.color}>
                    <img src={skill.icon} alt={skill.name} />
                  </IconWrapper>
                  <SkillName>{skill.name}</SkillName>
                </TopSection>

                <ArticlePreview $size={index % 6}>
                  <ArticleContent $size={index % 6}>
                    <ArticleTitle>{t(skill.article?.title)}</ArticleTitle>
                    <ArticleText $size={index % 6}>
                      {t(skill.article?.preview)}
                    </ArticleText>
                  </ArticleContent>
                  <ReadMore
                    target="_blank"
                    rel="noopener noreferrer"
                    href={skill.article?.link}
                    $color={skill.color}
                  >
                    {t("En savoir plus !")}
                  </ReadMore>
                </ArticlePreview>
              </CardContent>
            </Card>
          ))}
        </MasonryGrid>
      </Container>
    </Section>
  );
};

const ArticlePreview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.$size === 0 || props.$size === 2 ? "space-between" : "flex-end"};
  flex: 1;
  min-height: 0;
`;

const ArticleContent = styled.div`
  display: ${(props) =>
    props.$size === 0 || props.$size === 2 ? "block" : "none"};

  @media (max-width: 768px) {
    display: none;
  }
`;

const CardContent = styled.div`
  position: relative;
  height: calc(100% - 48px);
  padding: clamp(1rem, 1.5vw, 1.5rem);
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  gap: clamp(0.5rem, 1vw, 1rem);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: clamp(0.75rem, 1vw, 1rem);
  margin-bottom: clamp(0.75rem, 1vw, 1rem);
`;

const IconWrapper = styled.div`
  background: ${(props) => `${props.$color}15`};
  min-width: clamp(35px, 4vw, 45px);
  height: clamp(35px, 4vw, 45px);
  padding: clamp(8px, 1vw, 10px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) => `${props.$color}30`};
    transform: scale(1.1);
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: end;
`;

const Container = styled.div`
  display: flex;
  width: fit-content;
  height: min-content;
`;

// const MasonryGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(15, minmax(250px, 300px));
//   grid-template-rows: repeat(3, minmax(150px, 200px));
//   grid-auto-flow: dense;
//   gap: clamp(0.5rem, 1vw, 1rem);
//   padding: clamp(1rem, 1.5vw, 1.5rem);
//   width: fit-content;
//   height: fit-content;
//   align-items: start;

//   @media (max-width: 1440px) {
//     grid-template-columns: repeat(10, minmax(250px, 300px));
//     grid-template-rows: repeat(4, minmax(150px, 200px));
//   }

//   @media (max-width: 1024px) {
//     grid-template-columns: repeat(6, minmax(200px, 250px));
//     grid-template-rows: repeat(6, minmax(130px, 180px));
//   }

//   @media (max-width: 768px) {
//     grid-template-columns: repeat(3, minmax(180px, 220px));
//     grid-template-rows: repeat(8, minmax(120px, 160px));
//   }

//   @media (max-width: 480px) {
//     grid-template-columns: repeat(2, minmax(150px, 180px));
//     grid-template-rows: repeat(12, minmax(100px, 140px));
//   }
// `;

const MasonryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(15, minmax(250px, 300px));
  grid-template-rows: repeat(2, minmax(140px, 180px));
  grid-auto-flow: dense;
  gap: clamp(0.5rem, 1vw, 1rem);
  padding: clamp(1rem, 1.5vw, 1.5rem);
  width: fit-content;
  height: fit-content;
  align-items: start;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(10, minmax(250px, 300px));
    grid-template-rows: repeat(3, minmax(140px, 180px));
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(6, minmax(200px, 250px));
    grid-template-rows: repeat(5, minmax(130px, 180px));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, minmax(180px, 220px));
    grid-template-rows: repeat(8, minmax(120px, 160px));
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, minmax(150px, 180px));
    grid-template-rows: repeat(12, minmax(100px, 140px));
  }
`;

const getCardSpan = (size) => {
  const defaultSpan = `
    grid-column: span 1;
    grid-row: span 1;
  `;

  const spans = {
    0: `
      grid-column: span 2;
      grid-row: span 2;
      @media (max-width: 768px) {
        grid-column: span 1;
        grid-row: span 1;
      }
    `,
    1: `
      grid-column: span 2;
      grid-row: span 1;
      @media (max-width: 768px) {
        grid-column: span 1;
        grid-row: span 1;
      }
    `,
    2: `
      grid-column: span 1;
      grid-row: span 2;
      @media (max-width: 768px) {
        grid-column: span 1;
        grid-row: span 1;
      }
    `
  };

  return spans[size] || defaultSpan;
};

const Card = styled.div`
  ${(props) => getCardSpan(props.$size)}
  position: relative;
  border-radius: clamp(8px, 1vw, 12px);
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  height: 100%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    ${(props) => `
      border: 1px solid ${props.$color}40;
      background: rgba(255, 255, 255, 0.08);
    `}
  }
`;

const SkillName = styled.h3`
  color: white;
  font-size: clamp(0.85rem, 1.1vw, 1.2rem);
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.5px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    font-weight: 400;
  }
`;

const ArticleTitle = styled.h4`
  color: white;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 400;
  margin: 0 0 0.5rem 0;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const ArticleText = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: clamp(0.7rem, 0.9vw, 0.85rem);
  line-height: 1.4;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => (props.$size === 0 ? 6 : 3)};

  @media (max-width: 768px) {
    font-size: 0.75rem;
    -webkit-line-clamp: 2;
    line-height: 1.3;
  }
`;

const ReadMore = styled.a`
  color: ${(props) => props.$color};
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 0.75rem;
  display: inline-block;
  transition: all 0.3s ease;
  opacity: 0.9;

  &:hover {
    opacity: 1;
    transform: translateX(5px);
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
    padding-top: 0.5rem;
  }
`;
export default SkillsSection;
