import React, { createContext, useState } from 'react';
export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [preloadedVideos, setPreloadedVideos] = useState({});
  return (
    <VideoContext.Provider value={{ preloadedVideos, setPreloadedVideos }}>
      {children}
    </VideoContext.Provider>
  );
};