import { components } from "react-select";
import styled from "styled-components";
import Select from "./Select";
import "flag-icons/css/flag-icons.min.css";

const langs = {
  en: {
    label: "FR",
    flag: "fr"
  },
  fr: {
    label: "EN",
    flag: "gb"
  }
};

const LangSelect = ({ currentLang, changeLanguage }) => {
  return (
    <SelectWrapper>
      <Select
        isSearchable={false}
        value={{
          label: langs[currentLang].label,
          value: currentLang,
          flag: langs[currentLang].flag
        }}
        options={Object.entries(langs).map(([key, value]) => ({
          label: value.label,
          value: key,
          flag: value.flag
        }))}
        onChange={(e) => {
          if (e) {
            changeLanguage(e.value);
          }
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          Option: ({ data, ...optionProps }) => (
            <components.Option {...optionProps} data={data}>
              <Row>
                <Flag className={`fi fi-${data.flag}`} />
                <Label>{data.label}</Label>
              </Row>
            </components.Option>
          ),
          SingleValue: ({ data, ...singleValueProps }) => (
            <components.SingleValue {...singleValueProps} data={data}>
              <Row>
                <Flag className={`fi fi-${data.flag}`} />
                <Label>{data.label}</Label>
              </Row>
            </components.SingleValue>
          )
        }}
      />
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  width: 100px;
  mix-blend-mode: difference;

  .react-select__control {
    min-height: unset;
    padding: 8px;
  }

  .react-select__single-value {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }

  .react-select__menu {
    background: #000;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .react-select__option {
    background: transparent;
    color: #fff;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 16px;
    z-index: 1000;

    &:hover,
    &:focus {
      background: rgba(255, 255, 255, 0.1);
    }

    &.react-select__option--is-selected {
      background: rgba(255, 255, 255, 0.2);
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Flag = styled.span`
  width: 24px;
  height: 18px;
  border-radius: 2px;
  object-fit: cover;
`;

const Label = styled.span`
  font-weight: 500;
  margin-top: 5px;
`;

export default LangSelect;
