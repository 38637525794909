import React, { useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { VideoContext } from "./VideoContext";
import { translate as t } from "../helpers/i18n";

const Card = ({ project, index }) => {
  const preRef = useRef(null);
  const videoRef = useRef(null);
  const { preloadedVideos } = useContext(VideoContext);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && preloadedVideos[project.video]) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: videoElement,
          start: "top bottom-=100",
          end: "bottom top+=100",
          scrub: 0.5,
          toggleActions: "play reverse play reverse"
        }
      });

      tl.fromTo(
        videoElement,
        {
          clipPath: "inset(100% 0 0 0)",
          opacity: 0,
          scale: 1.1,
          y: 50
        },
        {
          clipPath: "inset(0% 0 0 0)",
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 1.2,
          ease: "power4.out"
        }
      );

      return () => {
        if (tl.scrollTrigger) {
          tl.scrollTrigger.kill();
        }
      };
    }
  }, [project.video, preloadedVideos]);

  return (
    <>
      <Pre ref={preRef}>
        <p>{t(project.date)}</p>
        <Container>
          <h1>{t(project.title)}</h1>
        </Container>
        <p>{t(project.description)}</p>
        {project.link && (
          <Link target="_blank" rel="noopener noreferrer" href={`https://${project.link}/`}>
            {project.link}
          </Link>
        )}
        {Array.isArray(project.links) &&
          project.links.map((link, idx) => (
            <>
              <Link
                key={idx}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${link}/`}
              >
                {link}
              </Link>
              <br />
            </>
          ))}
      </Pre>
      {project.video && (
        <VideoContainer>
          <Video
            ref={videoRef}
            src={preloadedVideos[project.video]}
            autoPlay
            loop
            muted
            playsInline
          />
        </VideoContainer>
      )}
    </>
  );
};

const VideoContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  opacity: 0;
  transform: scale(1.1);
  transition: transform 0.3s ease;
  will-change: transform, opacity, clip-path;

  &:hover {
    transform: scale(1.02);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    transform: rotate(225deg);
    margin-left: 15px;
  }
`;

const Pre = styled.div`
  mix-blend-mode: difference;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  max-width: calc(555px - 4rem);

  &::before {
    inset: 0.75rem;
    border-radius: inherit;
    background: black;
    z-index: -1;
    transform: translateZ(-50px);
    filter: blur(15px);
    opacity: 0.5;
  }
  &::after {
    z-index: -2;
    inset: -1rem;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    & > h1 {
      font-size: 30px;
    }
  }
  @media (max-width: 400px) {
    font-size: 14px;
    & > h1 {
      font-size: 25px;
    }
  }
`;

const Link = styled.a`
  mix-blend-mode: difference;
  color: white;
  text-decoration: none;
  outline: none;
  padding: 15px 0;
`;

export default Card;
