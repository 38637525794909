import ReactSelect, { GroupBase, Props } from 'react-select'


const Select = (props) => {
  return (
    <ReactSelect
      classNamePrefix="react-select"
      {...props}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
          ...theme.colors,
          primary: '#fff',
          primary75: '#ffffffbf',
          primary50: '#ffffff80',
          primary25: '#ffffff40',
        },
      })}
      styles={{
        control: (base) => ({
          ...base,
          background: 'transparent',
          borderColor: 'transparent',
          boxShadow: 'none',
          cursor: 'pointer',
        }),
        singleValue: (base) => ({
          ...base,
          color: '#fff',
        }),
        menu: (base) => ({
          ...base,
          overflow: 'hidden',
        }),
      }}
    />
  )
}


export default Select