import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import styled from "styled-components";

const Cursor = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff !important;
  opacity: 0;
  position: fixed;
  z-index: 10000;
  user-select: none;
  pointer-events: none;
  mix-blend-mode: difference;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 0.3em #fff;
  transition: width 0.7s, height 0.7s, border-radius 0.7s;
`;

const CursorFollower = () => {
  const cursorRef = useRef(null);
  const videoRef = useRef(null);
  const isTouchDevice = "ontouchstart" in window;

  useEffect(() => {
    const handleMouseMoveEvent = (e) => {
      const { clientX, clientY } = e;
      if (cursorRef.current) {
        gsap.to(cursorRef.current, {
          x: clientX,
          y: clientY,
          duration: 0.1,
          ease: "power3.out",
          opacity: 1
        });
      }
    };

    window.addEventListener("mousemove", handleMouseMoveEvent);

    return () => {
      window.removeEventListener("mousemove", handleMouseMoveEvent);
    };
  }, [cursorRef, videoRef]);

  return isTouchDevice ? null : <Cursor ref={cursorRef} />;
};

export default CursorFollower;
