import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import gsap from "gsap";
import { translate as t } from "../helpers/i18n";


const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [status, setStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef(null);
  const headingRef = useRef(null);
  const subHeadingRef = useRef(null);
  const formElementsRef = useRef([]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(headingRef.current, {
        y: 100,
        opacity: 0,
        rotateX: -45
      });

      gsap.set(subHeadingRef.current, {
        y: 50,
        opacity: 0
      });

      gsap.set(formElementsRef.current, {
        x: -50,
        opacity: 0,
        stagger: 0.1
      });

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: formRef.current,
          start: "top center+=100",
          end: "bottom center",
          toggleActions: "play none none reverse"
        }
      });

      tl.to(headingRef.current, {
        y: 0,
        opacity: 1,
        rotateX: 0,
        duration: 1,
        ease: "power3.out"
      })
        .to(
          subHeadingRef.current,
          {
            y: 0,
            opacity: 1,
            duration: 0.8,
            ease: "power2.out"
          },
          "-=0.5"
        )
        .to(
          formElementsRef.current,
          {
            x: 0,
            opacity: 1,
            stagger: 0.1,
            duration: 0.8,
            ease: "power2.out",
            clearProps: "all"
          },
          "-=0.5"
        );

      formElementsRef.current.forEach((element) => {
        if (element) {
          element.addEventListener("mouseenter", () => {
            gsap.to(element, {
              scale: 1.02,
              duration: 0.3,
              ease: "power2.out"
            });
          });

          element.addEventListener("mouseleave", () => {
            gsap.to(element, {
              scale: 1,
              duration: 0.3,
              ease: "power2.out"
            });
          });
        }
      });
    }, formRef);

    return () => ctx.revert();
  }, []);

  const animateSuccess = () => {
    const tl = gsap.timeline();

    tl.to(formRef.current, {
      scale: 1.03,
      duration: 0.2,
      ease: "power2.out"
    })
      .to(formRef.current, {
        scale: 1,
        duration: 0.3,
        ease: "elastic.out(1, 0.5)"
      })
      .to(formElementsRef.current, {
        y: 10,
        opacity: 0,
        stagger: 0.05,
        duration: 0.3
      })
      .to(formElementsRef.current, {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 0.3
      });
  };

  const animateError = () => {
    const tl = gsap.timeline();

    tl.to(formRef.current, {
      x: -10,
      duration: 0.1,
      ease: "none"
    })
      .to(formRef.current, {
        x: 10,
        duration: 0.1,
        ease: "none",
        repeat: 2,
        yoyo: true
      })
      .to(formRef.current, {
        x: 0,
        duration: 0.1,
        ease: "power2.out"
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus("sending");

    try {
      await emailjs.send(
        "service_8bl2cvw",
        "template_cimt1ga",
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
          to_name: "Yann"
        },
        "m_ZbGHhbxnSXAQZc-"
      );

      setStatus("success");
      setFormData({ name: "", email: "", message: "" });
      animateSuccess();
    } catch (error) {
      animateError();
    }
    setIsSubmitting(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <FormSection>
      <FormContainer ref={formRef}>
        <HeadingWrapper>
          <MainHeading ref={headingRef}>{t("Travaillons ensemble !")}</MainHeading>
          <SubHeading ref={subHeadingRef}>
          {t("Have a project in mind? I'd love to hear about it.")}
          </SubHeading>
        </HeadingWrapper>

        <Form onSubmit={handleSubmit}>
          <InputGroup ref={(el) => (formElementsRef.current[0] = el)}>
            <Label htmlFor="name">{t("Name")}</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder={t("What's your name?")}
            />
          </InputGroup>

          <InputGroup ref={(el) => (formElementsRef.current[1] = el)}>
            <Label htmlFor="email">{t("Email")}</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder={t("Where can I reach you?")}
            />
          </InputGroup>

          <InputGroup ref={(el) => (formElementsRef.current[2] = el)}>
            <Label htmlFor="message">{t("Message")}</Label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              placeholder={t("Tell me about your project...")}
              rows={6}
            />
          </InputGroup>

          <Button
            type="submit"
            disabled={isSubmitting}
            ref={(el) => (formElementsRef.current[3] = el)}
          >
            {isSubmitting ? t("Envoi en cours...") : t("Envoyer")}
          </Button>

          {status && (
            <StatusMessage success={status === "success"}>
              {status === "success"
                && t("Message envoyé avec succès !")}
            </StatusMessage>
          )}
        </Form>
      </FormContainer>
    </FormSection>
  );
};

const FormSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 4rem 0;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeadingWrapper = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const MainHeading = styled.h2`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 700;
  background: linear-gradient(45deg, #fff, #888);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  transform-style: preserve-3d;
  perspective: 1000px;
`;

const SubHeading = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #888;
  font-weight: 400;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: clamp(1.5rem, 4vw, 2.5rem);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform-style: preserve-3d;
  will-change: transform;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transform-origin: center left;
  will-change: transform, opacity;
`;

const Label = styled.label`
  color: #888;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const Input = styled.input`
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1rem;
  font-family: inherit;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.07);
    transform: translateY(-2px);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-family: inherit;
    font-size: 1rem;
  }
`;

const TextArea = styled(Input).attrs({ as: "textarea" })`
  height: 150px;
  resize: none;
  overflow-y: auto;
  line-height: 1.5;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
`;

const Button = styled.button`
  padding: 1rem 2rem;
  border-radius: 8px;
  border: none;
  background: white;
  color: black;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  span {
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.9);

    span {
      transform: translateX(5px);
    }
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const StatusMessage = styled.p`
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  color: ${(props) => (props.success ? "#00ff00" : "#ff0000")};
  background: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export default ContactForm;
