import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const projects = [
  {
    id: 9,
    title: " ",
    date: " ",
    description:
      " "
  },
  {
    id: 8,
    title: "smsmode",
    date: "Avril 2021 - Aujourd'hui",
    video: "/smsmode.mp4",
    links: [
      "smsmode.com",
      "ui.smsmode.com",
      "dev.smsmode.com",
    ],
    description:
      "Développeur Front-end chez smsmode, une plateforme B2C permettant aux entreprises d'envoyer des SMS directement aux consommateurs, garantissant une communication rapide et fiable pour le marketing et les notifications."
  },
  {
    id: 6,
    title: "Parachutisme",
    date: "Mai 2022 - Aujourd'hui (75 sauts)",
    video: "/exit.mp4",
    link: "ffp.asso.fr/methodes-denseignement-et-brevets",
    description:
      "Expérience de parachutisme, explorant la sensation paradoxale de tomber tout en étant soutenu par l'air."
  },
  {
    id: 4,
    title: "Freelance",
    date: "Octobre 2020 - Aujourd'hui",
    video: "/symble.webm",
    description:
      "Développeur freelance sur divers projets, notamment la création et la maintenance de sites web et d'applications.",
    links: [
      "medemprunt.fr",
      "movingontransport.fr",
      "pourquemarseillevive.com",
      "nordpro.fr",
    ]
  },
  {
    id: 3,
    title: "Assistant EPITECH Region",
    date: "Mars - Octobre 2020",
    video: "/webacademie.mp4",
    description:
      "J'ai accompagné des étudiants de la Web Académie, en réinsertion professionnelle dans l'apprentissage du code @ EPITECH.",
    link: "webacademie.org"
  },
  {
    id: 2,
    title: "Transpare",
    date: "Juillet - Décembre 2019",
    description:
      "Stage React a Transpare, une start-up innovante développant une plateforme simplifiant les échanges entre importateurs, exportateurs et transitaires.",
    video: "/transpare.mov"
  },
  {
    id: 1,
    title: "EPITECH, European Institute of Technology",
    date: "Septembre 2018 - Juin 2023",
    video: "/epitech.mp4",
    description:
      "Découverte de la programmation en 2018, rejoignant la promotion 2023 après le bac. Diplômé d'EPITECH en 2023."
  }
];

const Timeline = () => {
  const timelineRef = useRef(null);

  useEffect(() => {
    const timeline = timelineRef.current;
    const timelineItems = timeline.querySelectorAll('.timeline-item');
    const dots = timeline.querySelectorAll('.timeline-dot');
    const lines = timeline.querySelectorAll('.timeline-line');
    const cards = timeline.querySelectorAll('.timeline-card');

    gsap.registerPlugin(ScrollTrigger);

    gsap.set(cards, { 
      opacity: 0,
      x: (index) => index % 2 === 0 ? 100 : -100 
    });
    gsap.set(dots, { scale: 0 });
    gsap.set(lines, { scaleY: 0, transformOrigin: "top" });

    const mm = gsap.matchMedia();

    mm.add("(min-width: 769px)", () => {
      timelineItems.forEach((item, index) => {
        const dot = dots[index];
        const line = lines[index];
        const card = cards[index];

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: "top center",
            end: "bottom center",
            toggleActions: "play none none reverse",
          }
        });

        tl.to(dot, {
          scale: 1,
          duration: 0.4,
          ease: "back.out(1.7)"
        })
        .to(line, {
          scaleY: 1,
          duration: 0.8,
          ease: "power2.out"
        }, "-=0.2")
        .to(card, {
          opacity: 1,
          x: 0,
          duration: 0.8,
          ease: "power2.out"
        }, "-=0.6");
      });
    });

    mm.add("(max-width: 768px)", () => {
      timelineItems.forEach((item, index) => {
        const dot = dots[index];
        const line = lines[index];
        const card = cards[index];

        gsap.set(card, { x: 0, y: 50 });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: "top center+=100",
            end: "bottom center",
            toggleActions: "play none none reverse"
          }
        });

        tl.to(dot, {
          scale: 1,
          duration: 0.4,
          ease: "back.out(1.7)"
        })
        .to(line, {
          scaleY: 1,
          duration: 0.8,
          ease: "power2.out"
        }, "-=0.2")
        .to(card, {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: "power2.out"
        }, "-=0.6");
      });
    });

    return () => {
      mm.revert();
    };
  }, []);

  return (
    <TimelineContainer ref={timelineRef}>
      {projects.map((project, index) => (
        <TimelineItem className="timeline-item" key={project.id}>
          <TimelineContent className="timeline-card" index={index}>
            <Card project={project} index={index} />
          </TimelineContent>
          <DotLineContainer>
            <Dot className="timeline-dot" />
            <Line className="timeline-line" index={index} />
          </DotLineContainer>
        </TimelineItem>
      ))}
    </TimelineContainer>
  );
};



const TimelineContainer = styled.div`
  position: relative;
  padding: 2rem 0;
  max-width: 1200px;
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const TimelineItem = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-direction: row-reverse;
  min-height: 600px;
  
  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: row-reverse !important;
    min-height: 400px;
    gap: 15px;
  }

  @media (max-width: 480px) {
    min-height: 300px;
    gap: 10px;
  }
`;

const TimelineContent = styled.div`
  flex: 1;
  color: #fff;
  padding: 20px;
  padding-top: 0;
  height: 100%;
  border-radius: 5px;
  max-width: calc(50% - 20px);
  
  & > * > * {
    margin: 10px 0;
  }

  @media (max-width: 768px) {
    max-width: calc(100% - 40px);
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const DotLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
`;

const Dot = styled.div`
  position: sticky;
  top: 155px;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0.3em #000;
  z-index: 2;

  @media (max-width: 480px) {
    width: 12px;
    height: 12px;
    top: 120px;
  }
`;

const Line = styled.div`
  height: 100%;
  border-radius: 1rem;
  margin-top: -15px;
  width: 2px;
  background: ${({ index }) =>
    index % 2 === 0
      ? "linear-gradient(to bottom, #000, #FFF)"
      : "linear-gradient(to bottom, #FFF, #000)"};
  transform-origin: top;

  @media (max-width: 480px) {
    margin-top: -12px;
  }
`;

export default Timeline;