import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Music } from "lucide-react";
import * as THREE from "three";

const SpotifyNowPlaying = () => {
  const [songData, setSongData] = useState(null);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const barsRef = useRef([]);
  const animationRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(75, 300 / 80, 0.1, 1000);
    camera.position.z = 15;
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true
    });
    renderer.setSize(300, 80);
    rendererRef.current = renderer;
    containerRef.current.appendChild(renderer.domElement);

    const barCount = 32;
    const barGeometry = new THREE.BoxGeometry(0.4, 1, 1);
    const bars = [];

    for (let i = 0; i < barCount; i++) {
      const barMaterial = new THREE.MeshBasicMaterial({
        color: new THREE.Color().setHSL(i / barCount, 0.7, 0.5),
        transparent: true,
        opacity: 0.8
      });

      const bar = new THREE.Mesh(barGeometry, barMaterial);
      bar.position.x = (i - barCount / 2) * 0.8;
      bar.position.y = 0;
      bar.position.z = 0;
      scene.add(bar);
      bars.push(bar);
    }
    barsRef.current = bars;

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (containerRef.current && renderer.domElement) {
        containerRef.current.removeChild(renderer.domElement);
      }
      scene.clear();
      renderer.dispose();
    };
  }, []);

  useEffect(() => {
    if (
      !sceneRef.current ||
      !rendererRef.current ||
      !cameraRef.current ||
      !songData
    )
      return;

    const animate = () => {
      animationRef.current = requestAnimationFrame(animate);

      const energy = songData.energy || 0.5;
      const tempo = songData.tempo || 120;
      const danceability = songData.danceability || 0.5;
      const beat = songData.beat || 4;

      barsRef.current.forEach((bar, i) => {
        const time = Date.now() * (tempo / 12000);
        const pulseOffset = (i / barsRef.current.length) * Math.PI * 2;

        let height = Math.sin(time + pulseOffset) * energy * 3;

        height += Math.cos(time * danceability + pulseOffset) * 1.5;

        height = Math.max(0.2, Math.abs(height));

        bar.scale.y = height;
        bar.position.y = height / 2;

        const hue = i / barsRef.current.length + time * 0.1;
        const saturation = 0.5 + energy * 0.5;
        const lightness = 0.4 + danceability * 0.3;
        bar.material.color.setHSL(hue, saturation, lightness);

        bar.rotation.z = Math.sin(time + pulseOffset) * 0.1;
      });

      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };

    animate();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [songData]);

  useEffect(() => {
    const fetchNowPlaying = async () => {
      try {
        const response = await fetch("/api/now-playing");
        const data = await response.json();
        setSongData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching now playing:", error);
        setLoading(false);
      }
    };

    fetchNowPlaying();
    const interval = setInterval(fetchNowPlaying, 3000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <Container>Loading...</Container>;
  }

  if (!songData?.isPlaying) {
    return (
      <Container>
        <NoMusic>
          <Music size={20} />
          <span>Pas de musique en cours</span>
        </NoMusic>
      </Container>
    );
  }

  const trackId = songData.songUrl.split("/").pop();

  return (
    <Container>
      <VisualizerContainer ref={containerRef} />
      <Content>
        <PlayerWrapper>
          <iframe
            src={`https://open.spotify.com/embed/track/${trackId}?utm_source=generator&theme=0`}
            width="100%"
            height="80"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            style={{ opacity: 0.8 }}
          />
        </PlayerWrapper>
      </Content>
      <InfoOverlay>
        <AlbumImage src={songData.albumImageUrl} alt={songData.album} />
        <Info>
          <Title>{songData.title}</Title>
          <Artist>{songData.artist}</Artist>
        </Info>
        <MusicIcon>
          <Music size={20} />
        </MusicIcon>
      </InfoOverlay>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`;

const PlayerWrapper = styled.div`
  width: 100%;
  background: transparent;

  iframe {
    display: block;
    border-radius: 12px;
    background: transparent;
  }
`;

const VisualizerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 1;
  canvas {
    opacity: 0.8;
  }
`;

const InfoOverlay = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 3;
`;

const AlbumImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
  object-fit: cover;
`;

const Info = styled.div`
  flex: 1;
  min-width: 0;
`;

const Title = styled.div`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
`;

const Artist = styled.div`
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(255, 255, 255, 0.8);
`;

const NoMusic = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.8);
  padding: 12px;
`;

const MusicIcon = styled.div`
  color: rgba(255, 255, 255, 0.8);
`;

export default SpotifyNowPlaying;
