import React, { useEffect, useRef } from "react";
import styled from 'styled-components';
import { gsap } from "gsap";

const Loader = ({ setIsLoading }) => {
  const loaderRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        gsap.to(loaderRef.current, {
          opacity: 0,
          duration: 0.8,
          ease: "power2.inOut",
          onComplete: () => {
            setIsLoading(false);
            if (loaderRef.current) {
              loaderRef.current.style.display = 'none';
            }
          }
        });
      }
    });

    tl.fromTo(lineRef.current, 
      {
        scaleY: 0,
        opacity: 0
      },
      {
        scaleY: 1,
        opacity: 1,
        duration: 1.2,
        ease: "power2.out"
      }
    )
    .to(lineRef.current, {
      scaleY: 0,
      opacity: 0,
      duration: 0.8,
      ease: "power2.inOut"
    });

  }, [setIsLoading]);

  return (
    <LoaderWrapper ref={loaderRef}>
      <Line ref={lineRef} />
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  position: fixed;
  inset: 0;
  background: black;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  width: 2px;
  height: 100vh;
  background: white;
  transform-origin: top;
  border-radius: 1rem;
`;

export default Loader;